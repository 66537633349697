import { Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ConfirmationModal(props) {
    
    const [show, setShow] = useState(true);

    const close = () => {
        setShow(false);
        props.onCancel();
    }

    return (
        <Modal show={show} onHide={close} className={props.className ? props.className : ''}>
            <Modal.Header closeButton>
            <Modal.Title dangerouslySetInnerHTML={{__html: props.title}} />
            </Modal.Header>
            <Modal.Body dangerouslySetInnerHTML={{__html: props.body}} />
            <Modal.Footer>
            {props.noCancel ? <Fragment/> : <Button variant="secondary" onClick={close}>Close</Button>}
            <Button variant="primary" onClick={() => {
                close();
                props.onConfirm();
            }}>
                {props.confirmText ? props.confirmText : 'Yes'}
            </Button>
            </Modal.Footer>
        </Modal>
    );
}