import React, { useState, Fragment } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SchedulesPage from './pages/SchedulesPage.js';
import LoginPage from './pages/LoginPage.js';
import ConfigPage from './pages/ConfigPage.js';
import SignupPage from './pages/SignupPage.js';
import RotaPage from './pages/RotaPage.js';
import ExtraSupportPage from './pages/ExtraSupportPage.js';
import LaundryPage from './pages/LaundryPage.js';
import TemplesPage from './pages/TemplesPage.js';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<RotaPage />} />
          <Route path="index.html" element={<RotaPage />} />
          <Route path="config" element={<ConfigPage />} />
          <Route path="schedules" element={<SchedulesPage />} />
          {/* <Route path="signup" element={<SignupPage />} /> */}
          <Route path="rota" element={<RotaPage />} />
          <Route path="extra" element={<ExtraSupportPage />} />
          <Route path="laundry" element={<LaundryPage />} />
          <Route path="temple" element={<TemplesPage temple='Temple' />} />
          <Route path="login" element={<LoginPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
