import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import "react-datepicker/dist/react-datepicker.css";
import { useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import ConfirmationModal from '../components/ConfirmationModal.js';
import TempleSignupModal from '../components/TempleSignupModal.js';
import config from "../config.js";
import { genericErrorMsg, guestToString, isLaundryUser } from "../utils.js";

export default function TemplesPage(props) {

  const [searchParams, setSearchParams] = useSearchParams();

  var utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  var weekOfYear = require('dayjs/plugin/weekOfYear')
  dayjs.extend(weekOfYear)
  var isSameOrAfter = require("dayjs/plugin/isSameOrAfter")
  dayjs.extend(isSameOrAfter)
  var timezone = require('dayjs/plugin/timezone');
  dayjs.extend(timezone);

  var _ = require('lodash')

  const startDate = dayjs().subtract(8, 'hour').startOf('day');
  const endDate = dayjs().subtract(8, 'hour').startOf('day').add(1, 'day');//dayjs().endOf('week').endOf('day').add(1, 'week').add(1, 'day');

  const [templeSignups, setTempleSignups] = useState(null);
  const [dates, setDates] = useState([]);

  const [guestsOptions, setGuestsOptions] = useState([]);

  const [showSignupModal, setShowSignupModal] = useState(false);
  const [selectedSignup, setSelectedSignup] = useState(null);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [showConfirmPolicyModal, setShowConfirmPolicyModal] = useState(false);

  //const [showVideoGuidelinesModal, setShowVideoGuidelinesModal] = useState(false);
  //const [showPhotoGuidelinesModal, setShowPhotoGuidelinesModal] = useState(false);

  const fetchTempleSignups = () => {
    fetch(`${config.server_base_url}/api/templeSignups`)
        .then((response) => response.json())
        .then((data) => setTempleSignups(data.templeSignups.filter(ts=>ts.temple==props.temple)))
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  } 

  const fetchGuests = () => {
    fetch(`${config.server_base_url}/api/guests`)
        .then((response) => response.json())
        .then((data) => {
          setGuestsOptions([{value: '', label: 'choose your name'}, ...data.guests.map(g=>{ return {value: g.id, label: `${g.preferredName} ${g.lastName.slice(0,3)}.`}})]);
        })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }  

  const checkout = (signupId) => {
    fetch(`${config.server_base_url}/api/templeSignups/${signupId}/checkout`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) fetchTempleSignups() })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  } 

  
  useEffect(() => {
    fetchGuests();
    fetchTempleSignups();
    
    var dates = [];
    let currentDate = searchParams.get('yesterday')!=null || isLaundryUser() ? startDate.subtract(1, 'day') : (searchParams.get('lastweek')!=null ? startDate.subtract(7, 'day') : startDate);
    while (currentDate.isBefore(endDate)) {
      dates.push(currentDate);
      currentDate = currentDate.add(1, 'day');
    }
    setDates(dates);
  },[])

  return (
    <div id='app-container' className="app-container temple-signup-container">
      <div className='page-header' style={{padding: 35}}><h1 style={{color: '#6D301B'}}>Night Contemplation at the Advaita Temple of Emptiness</h1></div>
      <div id='page-container' className="page-container temple-signup-page" style={{paddingTop: 85}}> 
        <div>
          <p><img style={{width: "100%", maxWidth: 540}} src='https://cdn.mooji.org/wp-content/uploads/2025/02/09c6d709c389bf150aeffa92c7240b7c.jpg' /></p>
        </div>

        <p style={{maxWidth: 440}}>You are welcome to visit the Temple between 9:30pm and 7:00am. Please watch the video below.</p>
        <div style={{width: "100%", maxWidth: 540}} dangerouslySetInnerHTML={{__html:`
          <p><div style="position:relative;overflow:hidden;padding-bottom:177.78%"><iframe src="https://cdn.jwplayer.com/players/g5BQyviu-SfOKAZcA.html" width="100%" height="100%" frameborder="0" scrolling="auto" title="Temple Of Emptiness " style="position:absolute;" allowfullscreen></iframe></div></p>
          `}}>
        </div>
        <p style={{maxWidth: 540}}><b>It is required to sign in here when you arrive and to sign out when you have left. Upon sigining in you will receive the current code to open the temple.</b></p> 
        <p style={{maxWidth: 540}}>If someone is still sitting inside when you leave, please let them know they are now responsible for switching everything off and locking up. Remember to still check out online.</p>
        {templeSignups && templeSignups.length>0 ? 
        <Tabs className="mb-3">
          {dates.length && dates.map(date=>
            templeSignups.filter(signup=>dayjs(signup.date).isSame(date, 'day')).length> 0 ?
            <Tab eventKey={date.format('ddd D MMM')} key={date.format('ddd D MMM')} title={date.format('ddd D MMM')}>
              <div className='data-table-container'>
                <Table striped bordered hover className='temple-signups-table data-table'>
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Check in</td>
                      <td>Check out</td>
                    </tr>
                  </thead>
                  <tbody>
                    {templeSignups.filter(signup=>dayjs(signup.date).isSame(date, 'day')).map(signup=> 
                      <tr key={signup.id}>
                        <td style={{lineHeight: 2, maxWidth: 'calc(35vw)'}}>
                          {guestToString(signup.assignee)} <Button variant='link' style={{paddingLeft: 0}} onClick={()=>{ setSelectedSignup(signup); setShowConfirmPolicyModal(true)}}>{!signup.assignee ? 'Check in' : <span></span>}</Button>
                        </td>
                        <td style={{lineHeight: 2}}>
                          {signup?.checkinTime ? dayjs(signup.checkinTime).format('HH:mm') : ''}
                        </td>
                        <td style={{lineHeight: 2}}>
                          {signup.assignee !=null ? (signup.checkoutTime ? dayjs(signup.checkoutTime).format('HH:mm') : <Button variant='link' style={{paddingLeft: 0}} onClick={()=>{setSelectedSignup(signup); setShowCheckoutModal(true); }}>Check out</Button>) : <Fragment />}
                        </td>                        
                      </tr>)}
                  </tbody>
                </Table>
              </div>
            </Tab> :<Fragment key={date.format('ddd D MMM')} />)}               
        </Tabs> : <Fragment />}
      </div>  
      {showSignupModal && guestsOptions.length>0 ? <TempleSignupModal signup={selectedSignup} guests={guestsOptions} onCancel={()=>{ setShowSignupModal(false); fetchTempleSignups(); } } onSave={()=>{setShowPolicyModal(true); fetchTempleSignups(); }} /> : <span></span>}
      {showCheckoutModal ? <ConfirmationModal className='temple-signup-confirmation' title={`Confirm checkout ?`} body={`${guestToString(selectedSignup.assignee)} - ${dayjs(selectedSignup.date).tz('Europe/Lisbon').format('ddd D MMM')}`} onCancel={()=>setShowCheckoutModal(false)} onConfirm={()=>checkout(selectedSignup.id)} /> : <span></span>}

      {showConfirmPolicyModal ?<ConfirmationModal className='guidelines-confirmation' confirmText='I have understood the guidelines' title={`Night Visit Guidelines`} body={`
           <p><img style="width: 100%; max-width: 540" src='https://cdn.mooji.org/wp-content/uploads/2025/02/09c6d709c389bf150aeffa92c7240b7c.jpg' /></p>
           <p><div style="position:relative;overflow:hidden;padding-bottom:177.78%"><iframe src="https://cdn.jwplayer.com/players/g5BQyviu-SfOKAZcA.html" width="100%" height="100%" frameborder="0" scrolling="auto" title="Temple Of Emptiness " style="position:absolute;" allowfullscreen></iframe></div></p>
        `} noCancel onCancel={()=>setShowConfirmPolicyModal(false)} onConfirm={()=>{setShowConfirmPolicyModal(false); setShowSignupModal(true); }} /> : <span></span>}

      {/* {showVideoGuidelinesModal ?<ConfirmationModal className='temple-guidelines-modal' confirmText='OK' title={`Night Visit Guidelines`} noCancel body={`
          <p>Please confirm you have watched the guidelines</p>
        `} onCancel={()=>setShowVideoGuidelinesModal(false)} onConfirm={()=>{setShowVideoGuidelinesModal(false); }} /> : <span></span>} */}

      {/* {showPhotoGuidelinesModal ?<ConfirmationModal className='temple-guidelines-modal' confirmText='OK' title={`Temple Guidelines`} noCancel body={`
          <p><img style="width: 100%;" src='https://cdn.mooji.org/wp-content/uploads/2025/02/09c6d709c389bf150aeffa92c7240b7c.jpg'></p>
        `} onCancel={()=>setShowPhotoGuidelinesModal(false)} onConfirm={()=>{setShowPhotoGuidelinesModal(false); }} /> : <span></span>} */}

      {showPolicyModal ? <ConfirmationModal className='temple-signup-confirmation' confirmText='OK' title={`Thank you`} body={`
          <ul style='padding-left: 15px;'">
            <li>Lock code (for all padlocks): <b>0312</b><br/><i>do not share the code with anyone</i></li>
          </ul>
          
        `} onCancel={()=>setShowPolicyModal(false)} onConfirm={()=>setShowPolicyModal(false)} /> : <span></span>}
      <ToastContainer />
    </div>
  )
}